<template>
   <div class="col-lg-6 mb-1">
      <div class="card d-flex h-100 mb-0 cursor-pointer" @click="viewHistory">
         <div class="card-body d-flex p-3">
            <div class="row align-items-center font-12">
               <div class="w60 mr-2 d-none d-sm-block">
                  <div class="ratio ratio-1x1">
                     <img :src="client.avatar ? client.avatar : ''" class="rounded" alt="Avatar" @error="imageError" />
                  </div>
               </div>
               <div class="col">
                  <h1 class="weight-600 font-16 limitador m-b-10">{{ client.username == null ? $t('ecommerceMonitoring.unknown') : client.username }}</h1>
                  <p class="limitador-2 mb-0">
                     <i class="far fa-tags color-theme font-9 d-none d-xl-inline mr-1"></i><strong class="text-uppercase font-11 text-secondary d-none d-xl-inline mr-1">{{ $t('ecommerceMonitoring.permissions') }}: </strong>
                     <span v-if="client.premiumRoles.length == 0">{{ $t('ecommerceMonitoring.unknown') }}</span>
                     <span v-for="(permission, index) in client.premiumRoles" :key="index" class="badge ml-0 mr-1" :class="permission.active ? 'badge-success' : 'badge-danger'">
                        <span>{{ permission.namePermission }}</span>
                        <span class="text-secondary ml-1">Exp. {{ permission.expirationDate == null ? $t('ecommerceMonitoring.unknown') : String(permission.expirationDate.dayOfMonth).padStart(2, '0') +'/'+ String(permission.expirationDate.monthValue).padStart(2, '0') +'/'+ permission.expirationDate.year }}</span>
                     </span>
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Client',
   props: ['client', 'index'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      viewHistory : function () {
         this.$emit('history', this.client)
      }
   }
}

</script>