<template>
   <div class="col-12">
      <div class="card mb-1">
         <div class="card-body px-3 p-t-10 p-b-10 font-12">
            <h1 class="weight-600 font-16 limitador mb-0 text-center text-md-left" :class="permission.active ? 'text-green' : 'text-red'">{{ permission.namePermission == null ? $t('ecommerceMonitoring.unknown') : permission.namePermission }}</h1>
            <p class="font-10 limitador mb-2 text-center text-md-left text-secondary"><i class="far fa-sync font-8 mr-1"></i>{{ permission.active ? $t('ecommerceMonitoring.active') : $t('ecommerceMonitoring.inactive') }}</p>
            <div class="row text-center text-md-left">
               <div class="col-6">
                  <span class="limitador">
                     <i class="far fa-calendar font-10 mr-1" :class="permission.active ? 'text-green' : 'text-red'"></i>
                     <strong class="text-uppercase font-11 text-secondary d-none d-sm-inline mr-1">{{ $t('ecommerceMonitoring.purchaseDate') }}: </strong>
                     <span>{{ permission.date == null ? $t('ecommerceSales.unknown') : String(permission.date.dayOfMonth).padStart(2, '0') +'/'+ String(permission.date.monthValue).padStart(2, '0') +'/'+ permission.date.year }}</span>
                  </span>
               </div>
               <div class="col-6">
                  <span class="limitador">
                     <i class="far fa-calendar font-10 mr-1" :class="permission.active ? 'text-green' : 'text-red'"></i>
                     <strong class="text-uppercase font-11 text-secondary d-none d-sm-inline mr-1">{{ $t('ecommerceMonitoring.expirationDate') }}: </strong>
                     <span>{{ permission.expirationDate == null ? $t('ecommerceSales.unknown') : String(permission.expirationDate.dayOfMonth).padStart(2, '0') +'/'+ String(permission.expirationDate.monthValue).padStart(2, '0') +'/'+ permission.expirationDate.year }}</span>
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Permission',
   props: ['permission', 'index']
}

</script>