<template>
   <div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" :class="search.result.length > 0 ? '' : 'empty'" data-toggle="dropdown" aria-expanded="false">
            <i class="far fa-search font-17"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row m-0">
                  <div class="col-sm-6 col-lg-2 mb-3 px-1">
                     <label class="mb-1"><i class="far fa-sync font-12 color-theme mr-1"></i> Status</label>
                     <v-select class="rounded" title="Desktop" :options="$t('ecommerceMonitoring.status')" label="value" :reduce="s => s.key" v-model="search.status" />
                     <select title="Mobile" class="form-control" v-model="search.status">
                        <option v-for="(option, index) in $t('ecommerceMonitoring.status')" :key="index" :value="option.key">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-sm-6 col-lg-2 col-xl-3 mb-3 px-1">
                     <label class="mb-1"><i class="fab fa-discord font-12 color-theme mr-1"></i> {{ $t('ecommerceMonitoring.clientDiscord') }}</label>
                     <input type="text" class="form-control rounded" v-model="search.clientDiscord" maxlength="200" @keyup.enter="searchClients" />
                  </div>
                  <div class="col-sm-4 col-lg-3 col-xl-2 mb-3 px-1">
                     <label class="mb-1"><i class="far fa-stream font-12 color-theme mr-1"></i> {{ $t('ecommerceMonitoring.typeSearch') }}</label>
                     <v-select class="rounded" title="Desktop" :options="$t('ecommerceMonitoring.typeSearchList')" label="value" :reduce="s => s.key" v-model="search.type" />
                     <select title="Mobile" class="form-control" v-model="search.type">
                        <option v-for="(option, index) in $t('ecommerceMonitoring.typeSearchList')" :key="index" :value="option.key">{{ option.value }}</option>
                     </select>
                  </div>
                  <div class="col-sm-8 col-lg-4 px-0">
                     <div class="row m-0">
                        <div class="col-6 mb-3 px-1">
                           <label class="mb-1"><i class="far fa-calendar color-theme font-12 mr-1"></i> {{ $t("ecommerceMonitoring.initialDate") }}</label>
                           <datePicker class="rounded" title="Desktop" :format="'dd/MM/yyyy'" :language="ptBR" v-model="search.initialDate"></datePicker>
                           <input title="Mobile" class="form-control" type="date" v-model="search.initialDate" />
                        </div>
                        <div class="col-6 mb-3 px-1">
                           <label class="mb-1"><i class="far fa-calendar color-theme font-13 mr-1"></i> {{ $t("ecommerceMonitoring.finalDate") }}</label>
                           <datePicker class="rounded" title="Desktop" :format="'dd/MM/yyyy'" :language="ptBR" v-model="search.finalDate"></datePicker>
                           <input title="Mobile" class="form-control" type="date" v-model="search.finalDate" />
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-1 mb-3 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100 rounded" @click="searchClients">
                        <i class="fa fa-search font-13"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12">
         <div class="row">
            <client v-for="(client, index) in search.result" :key="index" :client="client" :index="index" @history="getClientOrders($event)" />
			</div>
		</div>

      <!-- modalClient -->
      <div class="modal fade" id="modalClient" aria-labelledby="modalClientLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalClientLabel">{{ modal.username }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-2">
                     <div class="card-body p-3">
                        <ul class="nav nav-tabs2">
                           <li class="nav-item">
                              <a class="nav-link show active" data-toggle="tab" href="#one">
                                 <i class="far fa-tags font-11 mr-lg-2 d-inline d-md-none d-lg-inline"></i>
                                 <span class="d-none d-md-inline">{{ $t("ecommerceMonitoring.permissions") }}</span>
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#two">
                                 <i class="far fa-clipboard font-12 mr-lg-2 d-inline d-md-none d-lg-inline"></i>
                                 <span class="d-none d-md-inline">{{ $t("ecommerceMonitoring.orderHistory") }}</span>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>

                  <div class="tab-content mt-0">
                     <div class="tab-pane show active" id="one">
                        <div class="row">
                           <permission v-for="(permission, index) in modal.premiumRoles" :key="index" :permission="permission" :index="index" />
                           <div class="col-12 text-center my-5" v-if="modal.premiumRoles.length == 0">{{ $t("ecommerceMonitoring.noRes") }}</div>
                        </div>
                     </div>
                     <div class="tab-pane" id="two">
                        <div class="row">
                           <order v-for="(order, index) in modal.orders" :key="index" :order="order" :index="index" :edit="false" @view="viewOrder($event)" />
                           <div class="col-12 text-center my-5" v-if="modal.orders.length == 0">{{ $t("ecommerceMonitoring.noRes") }}</div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.alterSettings.permission.button") }}</button>
               </div>
            </div>
         </div>
      </div>

      <!-- modalOrder -->
      <div class="modal fade" id="modalOrder" aria-labelledby="modalOrderLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalOrderLabel">{{ $t("ecommerceSales.view") }} - ID {{ modal2.id }}</h5>
                  <button type="button" class="close" @click="closeOrder" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-1">
                     <div class="card-body p-3">
                        <div class="row m-0">
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="fab fa-discord font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.clientDiscord') }}</label>
                              <input type="text" class="form-control" :value="modal2.nomeCompleto" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-sync font-12 color-theme mr-1"></i> Status</label>
                              <input type="text" class="form-control" :value="modal2.status" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-calendar font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.date') }}</label>
                              <input type="text" class="form-control" :value="modal2.data == null ? $t('ecommerceSales.unknown') : String(modal2.data.dayOfMonth).padStart(2, '0') +'/'+ String(modal2.data.monthValue).padStart(2, '0') +'/'+ modal2.data.year +' '+ String(modal2.data.hour).padStart(2, '0') +':'+ String(modal2.data.minute).padStart(2, '0')" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-calendar font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.lastUpdate') }}</label>
                              <input type="text" class="form-control" :value="modal2.dataUltMov == null ? $t('ecommerceSales.unknown') : String(modal2.dataUltMov.dayOfMonth).padStart(2, '0') +'/'+ String(modal2.dataUltMov.monthValue).padStart(2, '0') +'/'+ modal2.dataUltMov.year +' '+ String(modal2.dataUltMov.hour).padStart(2, '0') +':'+ String(modal2.dataUltMov.minute).padStart(2, '0')" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="fal fa-credit-card font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.paymentMethod') }}</label>
                              <input type="text" class="form-control" :value="modal2.pagamento" disabled />
                           </div>
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-money-bill-wave-alt font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.value') }}</label>
                              <input type="text" class="form-control" :value="modal2.valor" disabled />
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mb-1">
                     <div class="card-body p-3">
                        <div class="row m-0">
                           <div class="col-sm-6 px-1 m-b-10">
                              <label class="mb-1"><i class="far fa-shopping-cart font-12 color-theme mr-1"></i> {{ $t('ecommerceSales.products') }}</label>
                           </div>
                           <div class="col-12 px-1">
                              <div class="cards-1">
                                 <div class="card mb-0" v-for="(product, index) in modal2.produtos" :key="index">
                                    <div class="card-body p-2">
                                       <div class="row m-1">
                                          <div class="w60">
                                             <div class="ratio ratio-1x1"><img :src="product.icone ? product.icone : ''" @error="imageError" class="rounded" /></div>
                                          </div>
                                          <div class="col pl-2">
                                             <h1 class="limitador weight-600 font-15 mb-2">{{ product.nome }}</h1>
                                             <p class="limitador font-13 mb-0"><small>R$ </small>{{ parseFloat(product.valor).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-secondary" @click="closeOrder">{{ $t("ecommerceMonitoring.back") }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import order from '@/components/ecommerce/Order.vue'
import client from '@/components/ecommerce/Client.vue'
import permission from '@/components/ecommerce/Permission.vue'

export default {
   name: 'Ecommerce_Monitoring',
   data: function () {
      return {
         ptBR: ptBR,
         search: {'status': 'All', 'clientDiscord': null, 'idServer': null, 'type': 'Expiration date', 'initialDate': null, 'finalDate': null, 'result': []},
         modal: {'id': null, 'username': null, 'premiumRoles': [], 'orders': []},
         modal2: {
            'id': null, 'idCliente': null, 'idDiscord': null, 'nomeCompleto': null, 'idServer': null, 'data': {}, 'dataUltMov': {}, 'status': null, 
            'pagamento': null, 'urlPagamento': null, 'valor': 0, 'produtos': []
         },
      }
   },
   computed: {
      ... mapState({
         serverS: state => state.serverS,
         urlRest: state => state.urlRest
      })
   },
   components: {
      client, permission, order
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      searchClients : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.search.idServer = this.serverS.id_s;

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/searchEcommerceClients',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.search

         }).then((response) => {
            this.search.result = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      getClientOrders : function (client) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getClientOrdersEcommerce',
            params: {
               id: client.idUser
            }
         }).then((response) => {
            this.modal = {'id': client.idUser, 'username': client.username, 'premiumRoles': client.premiumRoles, 'orders': response.data}
            $('#modalClient').modal('show')

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      viewOrder : function (order) {
         this.modal2 = JSON.parse(JSON.stringify(order))
         $('#modalClient').modal('hide')
         $('#modalOrder').modal('show')
      },
      closeOrder : function () {
         $('#modalOrder').modal('hide')
         $('#modalClient').modal('show')
      },
      calcularData : function () {
         let dataI = new Date();
         let dataF = new Date();
         dataI.setDate(dataI.getDate() - 7);

         if (window.innerWidth < 767) {
            let zeroFill = n => {
               return ('0' + n).slice(-2);
            }

            this.search.initialDate = dataI.getFullYear() +'-'+ zeroFill((dataI.getMonth() + 1)) +'-'+ zeroFill(dataI.getDate());
            this.search.finalDate = dataF.getFullYear() +'-'+ zeroFill((dataF.getMonth() + 1)) +'-'+ zeroFill(dataF.getDate());

         } else {
            this.search.initialDate = dataI;
            this.search.finalDate = dataF;
         }
      }
   },
   mounted() {
      this.calcularData()
      $(window).resize(() => this.calcularData());

      $('#search-nav .dropdown-menu').on('click', function(e) {
         if (e.target.nodeName != 'BUTTON') {
            e.stopPropagation();
         }
      });
   }
}

</script>